import React, { Component, createRef } from 'react'
import '../assets/css/AboutmeEN.css'
//import ProfilePicture from '../assets/icons/PandaFaceWinkLogo.svg'
import ProfilePicture from '../assets/images/LCCPP.jpg'
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { Loadingcomp } from '../components/Loadingcomp.js'

export class AboutmeEN extends Component {


  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      showLoading: false,
      responsiveselectboxmargin: 'reswithmargin'
    };

    this.responsivewidth = createRef();
    this.responsiveheight = createRef();
    this.responsivemargin = createRef();
    this.responsivequality = createRef();
  }

  render() {
    return (
      <>
        <section id="about" className="about homesection">
          <div className='container darkmode-aboutme' id='cvdiv'>
            {/* ======= About Me ======= */}
            <div className="about-me aboutpdbtn">
              <div className="section-title">
                <h2>About</h2>
                <p>Luis Chuy Cho</p>
              </div>
              <div className="row">
                <div className="col-lg-4 ppimgdiv" data-aos="fade-right">
                  <img loading='lazy' src={ProfilePicture} className="img-fluid" alt="" />
                </div>
                <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                  <h3>Computer Science Engineer</h3>
                  <p className="fst-italic">
                    La Plazuela Neighborhood, Miguel de Cervantes Ave, 4th Street S, Tegucigalpa FM11101
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <ul>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Birthday:</strong> <span>23 Jan 1998</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Website:</strong> <span>www.luischuy.com</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Phone:</strong> <span>+504 3325 7444</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>City:</strong> <span>Tegucigalpa, HND</span></li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <ul>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Age:</strong> <span>26</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Degree:</strong> <span>Bachelor</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Email:</strong> <span>luischuy12345@gmail.com</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Freelance:</strong> <span>Available</span></li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    Graduated in Computer Science and Engineering, I am passionate about building software, coding, and solving problems. I’m always looking for new challenges and opportunities to improve my skills and learn new things. I work efficiently, focusing on providing useful and practical solutions to improve systems and processes. I always aim to write better code and stay updated with the latest trends and technologies in the field. I am committed to delivering software that is high-quality, reliable, and easy to use. If you’re interested in connecting or learning more about my work, feel free to reach out!
                  </p>
                </div>
              </div>
            </div>{/* End About Me */}
            {/* ======= Counts ======= */}
            <div className="counts aboutpdbtn" id='counts'>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="count-box">
                    <i className="bi bi-file-earmark-arrow-down headericon" />
                    <Link className='linknocss'><span className='borderanim' onClick={() => { this.downloadCV() }}>DL <i className="bi bi-cloud-download"></i></span></Link>
                    <p>Download CV</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                  <div className="count-box">
                    <i className="bi bi-lightbulb headericon" />
                    <Link className='linknocss'><span className='borderanim' onClick={() => { this.switchModes() }}>Switch <i className="bi bi-lamp"></i></span></Link>
                    <p>Light/Dark Mode</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                  <div className="count-box">
                    <i className="bi bi-journal-richtext headericon" />
                    <Link className='linknocss'><span className='borderanim' onClick={() => { this.openModal() }}>Go <i className="bi bi-box-arrow-right"></i></span></Link>
                    <p>Portafolio</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                  <div className="count-box">
                    <i className="bi bi-person-lines-fill headericon" />
                    <Link className='linknocss'><span className='borderanim' onClick={() => { this.openLoading() }}>Go <i className="bi bi-arrow-right"></i></span></Link>
                    <p>Contact Me</p>
                  </div>
                </div>
              </div>
            </div>{/* End Counts */}
            {/* ======= Skills  ======= */}
            <div className="skills skillspdbtm">
              <div className="section-title">
                <h2>Skills</h2>
              </div>
              <div className="row" style={{ marginTop: '40px' }}>
                <div className="col-lg-12">
                  <ul>
                    <li><i className="bi bi-caret-right-fill" /> <strong>Programming languages:</strong> <span>JavaScript, Typescript, PHP, SQL, C, C++, Java, C#.</span></li>
                    <li><i className="bi bi-caret-right-fill" /> <strong>Frameworks/Libraries:</strong> <span>AngularJS, Laravel, ReactJS, NodeJS, jQuery, VueJS, NuxtJS, NextJS, Ionic, Electron, DiscordJS.</span></li>
                    <li><i className="bi bi-caret-right-fill" /> <strong>Mobile Development:</strong> <span>Ionic, Cordova & Capacitor(IOS & Android).</span></li>
                    <li><i className="bi bi-caret-right-fill" /> <strong>Database management:</strong> <span>MySQL, Oracle, MongoDB, Firebase.</span></li>
                    <li><i className="bi bi-caret-right-fill" /> <strong>DevOps:</strong> <span>Git</span></li>
                    <li><i className="bi bi-caret-right-fill" /> <strong>Others:</strong> <span>MS Office, Adobe Illustrator, Photoshop, Premiere Pro, Corel, Google Maps/Direction Api, Google Analytics Data Api, CPanel.</span></li>
                  </ul>
                </div>
              </div>
            </div>{/* End Skills */}
            {/* ======= Interests ======= */}
            <div className="interests aboutpdbtn">
              <div className="section-title">
                <h2>Interests</h2>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-boombox" style={{ color: '#ffbb2c' }} />
                    <h3>Music</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-file-code" style={{ color: '#5578ff' }} />
                    <h3>Coding</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-controller" style={{ color: '#e80368' }} />
                    <h3>Video Games</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4 ">
                  <div className="icon-box">
                    <i className="bi bi-film" style={{ color: '#e361ff' }} />
                    <h3>TV/Series</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-book" style={{ color: '#47aeff' }} />
                    <h3>Reading</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-car-front-fill" style={{ color: '#ffa76e' }} />
                    <h3>Automobiles</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="fa-solid fa-volleyball" style={{ color: '#11dbcf' }} />
                    <h3>Sports</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="fa-solid fa-kitchen-set" style={{ color: '#4233ff' }} />
                    <h3>Cooking</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="fa-solid fa-person-walking" style={{ color: '#b2904f' }} />
                    <h3>Exercising and Healthcare</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-airplane" style={{ color: '#b20969' }} />
                    <h3>Traveling</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="fa-solid fa-person-hiking" style={{ color: '#ff5828' }} />
                    <h3>Outdoor Activities</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="fa-solid fa-hand-holding-hand" style={{ color: '#29cc61' }} />
                    <h3>Community Involvement</h3>
                  </div>
                </div>
              </div>
            </div>{/* End Interests */}
            {/* ======= Resume ======= */}
            <div className="resume aboutpdbtn">
              <div className="section-title">
                <h2>Resume</h2>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="resume-title">Education</h3>
                  <div className="resume-item">
                    <h4>Bachelor's in Computer Science and Engineering</h4>
                    <h5>Sept 2016 - June 2021</h5>
                    <p><em>Universidad Católica de Honduras, Tegucigalpa, HND</em></p>
                    <p>Graduated from the Universidad Católica de Honduras, Sagrado Corazón de Jesús campus, with a degree in Computer Science Engineering.</p>
                  </div>
                  <div className="resume-item">
                    <h4>Bachelor's in Science and Humanities</h4>
                    <h5>Aug 2006 - June 2016</h5>
                    <p><em>Nashville School, Tegucigalpa, HND</em></p>
                    <p>Completed Elementary, Middle, and High School education. Graduated from Nashville School Tegucigalpa with a bachelor's degree in Sciences and Humanities.</p>
                  </div>
                  <div className="resume-item">
                    <h4>Preschool and Basic Education</h4>
                    <h5>Aug 2002 - Jun 2006</h5>
                    <p><em>International School, Tegucigalpa, HND</em></p>
                    <p>Attended Preschool and part of Elementary School before transferring to Nashville School.</p>
                  </div>
                  <h3 className="resume-title">Certifications</h3>
                  <div className="resume-item">
                    <ul>
                      <li>CCNA1</li>
                      <li>CCNA2</li>
                    </ul>
                  </div>
                  <h3 className="resume-title">Volunteering</h3>
                  <div className="resume-item">
                    <h4>Honducamp.Tech</h4>
                    <h5>2018 - 2018</h5>
                    <p><em>Tegucigalpa, HND</em></p>
                  </div>
                  <div className="resume-item">
                    <h4>Hogar de Ancianos Salvador Aguirre</h4>
                    <h5>Jan 31 2020 - March 27 2020</h5>
                    <p><em>Tegucigalpa, HND</em></p>
                  </div>
                  <div className="resume-item">
                    <h4>Asociación China de Honduras</h4>
                    <p><em>Tegucigalpa, HND</em></p>
                  </div>
                  <h3 className="resume-title">Languages</h3>
                  <div className="resume-item">
                    <ul>
                      <li>Spanish (Native Language)</li>
                      <li>English</li>
                      <li>Cantonese (Listening-Basic+)</li>
                    </ul>
                  </div>
                  <h3 className="resume-title">Personal Experience/Projects</h3>
                  <div className="resume-item">
                    <ul>
                      <li>Designed interfaces for multiple landing pages for university projects.</li>
                      <li>Developed various software solutions for small and medium-sized businesses, including Barbería Jazmín and Empeños Anderson.</li>
                      <li>Created reservation software for managing laboratory and room bookings at UNICAH.</li>
                      <li>Implemented ISO 9001-2015 standards for small and medium-sized businesses.</li>
                      <li>Developed game software using C# and Java.</li>
                      <li>Built a portable game (Snake) using Arduino.</li>
                      <li>Conducted white-box and black-box testing for software.</li>
                      <li>Designed circuits using Multisim for various projects.</li>
                      <li>Created bots for Discord servers (Discord bots).</li>
                      <li>Wrote game scripts using C#.</li>
                      <li>Developed a personal website or portfolio page.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h3 className="resume-title">Professional Experience</h3>

                  <div className="resume-item">
                    <h4>Web Developer</h4>
                    <h5>July 2023 - Jan 2024</h5>
                    <p><em>Televicentro y Emisoras Unidas, Tegucigalpa, HND </em></p>
                    <ul>
                      <li>Redesigned the Deportes Televicentro website (facelift).</li>
                      <li>Managed SEO and fixed bugs on the following websites: Televicentro, Tu Nota, Radio HRN, and Deportes Televicentro.</li>
                      <li>Worked with the Laravel framework.</li>
                    </ul>
                    <p />
                  </div>

                  <div className="resume-item">
                    <h4>Software/Web Developer</h4>
                    <h5>June 2021 - Jan 2024</h5>
                    <p><em>Creative Productions, Tegucigalpa, HND </em></p>
                    <ul>
                      <li>Developed a mobile application (Android) for the Honduran Tourism Institute (IHT) using the Ionic Framework, AngularJS, TypeScript, HTML, and SCSS.
                        <ul>
                          <li>Implemented routes on maps using the Google Maps API.</li>
                          <li>Developed a real-time chat feature.</li>
                          <li>Integrated Google Analytics for screen views, event logging, and more.</li>
                          <li>Enabled user and business (premises) accessibility and management within the app (Content Management System).</li>
                        </ul>
                      </li>
                      <li>Developed a web dashboard with VueJS for managing premises, routes, users, and custom metrics integrated via Google Analytics API from mobile app data.</li>
                      <li>Mobile App (Android).
                        <ul>
                          <li>Developed a QR code and barcode scanner and generator.</li>
                        </ul>
                      </li>
                      <li>Created a ticketing software application using ElectronJS (with jQuery).
                        <ul>
                          <li>Managed full control of tickets, including creation, deletion, and reservation for events.</li>
                          <li>Designed seat reservation maps and ticket templates.</li>
                          <li>Provided ticket metrics and analytics.</li>
                          <li>Implemented user type control with specific access and restriction levels.</li>
                        </ul>
                      </li>
                      <li>Developed a mobile application (iOS) as a web page container app using the Ionic Framework, Capacitor, and Xcode, including publishing to the Apple Store.</li>
                      <li>Managed web hosting, including SSL setup, DNS/CDN configuration, DNSSEC, and Cloudflare DNS for multiple websites.</li>
                      <li>Conducted web hosting migrations, including websites and email accounts (migrating emails to Gmail and configuring SMTP & POP3/IMAP).</li>
                    </ul>
                    <p />
                  </div>
                  <div className="resume-item">
                    <h4>Project Technician</h4>
                    <h5>Nov 8 2021 - Dec 7 2021</h5>
                    <p><em>IMSA S. de R.L. de C.V (Inversiones Múltiples Salas Sociedad de Responsabilidad Limitada de Capital Variable), Tegucigalpa, HND</em></p>
                    <ul>
                      <li>Conducted white-box testing for software used in the transmission of preliminary election results (TREP).</li>
                      <li>Performed hardware testing on laptops, printers, modems, hubs, power generators, and antennas for compatibility with the TREP software.</li>
                      <li>Trained personnel on managing hardware and software, including troubleshooting potential issues and providing solutions.</li>
                      <li>Provided advanced Call Center support by assisting trained agents with problems related to the TREP system beyond their scope.</li>
                      <li>Served as a technician during the vote scrutiny process.</li>
                    </ul>
                    <p />
                  </div>
                  <div className="resume-item">
                    <h4>Merchant</h4>
                    <h5>Jan 2009 - Jan 2024</h5>
                    <p><em>Mercadito Francisco/Cho, Tegucigalpa, HND</em></p>
                    <ul>
                      <li>Identified market trends and opportunities.</li>
                      <li>Sourcing and negotiating with suppliers.</li>
                      <li>Managed inventory levels and oversaw stock replenishment</li>
                      <li>Established pricing strategies and promotional campaigns.</li>
                      <li>Handled financial and accounting operations for the business.</li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>{/* End About Section */}


        {/* ======= Download CV Modal ======= */}
        {this.state.showLoading ? <Loadingcomp /> : null}


        {/* ======= Download CV Modal ======= */}
        <div>
          <Modal show={this.state.isOpen} onHide={() => { this.closeModal() }} centered size='lg'>
            <Modal.Header>
              <Modal.Title>Download Options</Modal.Title>
              <i className="fa-sharp fa-solid fa-xmark closebtnoffmodal" onClick={() => this.closeModal()}></i>
            </Modal.Header>
            <Modal.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
              <div>
                <div className="tabbed">
                  <input type="radio" name="tabs" id="tab-nav-1" defaultChecked />
                  <label htmlFor="tab-nav-1"><p>Responsive</p></label>
                  <input type="radio" name="tabs" id="tab-nav-2" />
                  <label htmlFor="tab-nav-2"><p>Non-Responsive</p></label>
                  <input type="radio" name="tabs" id="tab-nav-3" />
                  <label htmlFor="tab-nav-3"><p>One Page</p></label>
                  <div className="tabs compaslctbx">
                    {/* tab for responsive*/}
                    <div>
                      <div className='ptagdnwldopt'>
                        <p><span style={{ color: '#FA6862' }}>My Backend Rest Api Source Code (currently down): </span><Link to='https://gitfront.io/r/user-2900320/KSMZ5cFemVz4/backendsqlportf/' target="_blank" rel="noopener noreferrer">https://gitfront.io/r/user-2900320/KSMZ5cFemVz4/backendsqlportf/</Link></p>
                        <p><span style={{ color: '#FA6862'}}>Note:</span> CV/Resume will be downloaded as it is as shown on your current screen (size, colors, etc).</p>
                        <p style={{ marginBottom: '5px'}}>PDF Options (<span style={{ color: '#FA6862'}}>not CV/Resume options</span>):</p>
                      </div>
                      <div className='ptagdnwldopt'>
                        <ul>
                          <li><i className="bi bi-caret-right-fill" /> Letter: 216 x 279 mm</li>
                          <li><i className="bi bi-caret-right-fill" /> Legal: 216 x 356 mm</li>
                          <li><i className="bi bi-caret-right-fill" /> Recommended Quality: 2+ (with margin), 4+ (without margin)</li>
                        </ul>
                      </div>
                      <div className='row'>
                        <div className='col-md-4 customargin1'>
                          <div className="maininput__ctn">
                            <input className="container__input" type="text" id="pdfwidth1" name="pdfwidth1" required defaultValue={216} ref={this.responsivewidth}></input>
                            <label htmlFor="pdfwidth1" className="container__label">Width</label>
                          </div>
                        </div>
                        <div className='col-md-4 customargin1'>
                          <div className="maininput__ctn">
                            <input className="container__input" type="text" id="pdfheight1" name="pdfheight1" required defaultValue={279} ref={this.responsiveheight}></input>
                            <label htmlFor="pdfheight1" className="container__label">Height</label>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 customargin1'>
                          <div className="select-box">
                            <div className="select-box__current" tabIndex={1}>
                              <div className="select-box__value">
                                <input className="select-box__input" type="radio" id={'responmargin1'} name="Responsive1" defaultValue={"reswithmargin"} onChange={(e) => this.selectboxmarginresponsive(e)} defaultChecked="checked" />
                                <p className="select-box__input-text">With Page Margin</p>
                              </div>
                              <div className="select-box__value">
                                <input className="select-box__input" type="radio" id={'responmargin2'} name="Responsive1" onChange={(e) => this.selectboxmarginresponsive(e)} defaultValue={"resnomargin"} />
                                <p className="select-box__input-text">No Page Margin (v2)</p>
                              </div>
                              <i className="bi bi-chevron-down select-box__icon"></i>
                            </div>
                            <ul className="select-box__list">
                              <li><label className="select-box__option" htmlFor={'responmargin1'}>With Page Margin :)</label></li>
                              <li><label className="select-box__option" htmlFor={'responmargin2'}>No Page Margin (v2) :)</label></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-4 customargin1'>
                          <div className="maininput__ctn">
                            <input className="container__input" type="text" id="pdfmargin1" name="pdfmargin1" required defaultValue={10} ref={this.responsivemargin}></input>
                            <label htmlFor="pdfmargin1" className="container__label">Margin</label>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-4 customargin1'>
                          <div className="maininput__ctn">
                            <input className="container__input" type="text" id="pdfquality1" name="pdfquality1" required defaultValue={2} ref={this.responsivequality}></input>
                            <label htmlFor="pdfquality1" className="container__label">Quality</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* tab for non-responsive*/}
                    <div>
                      <h2>CSS</h2>
                      <p>Maecenas dictum, urna ut consequat condimentum, est dui commodo diam, ac pretium dui ante eu quam. Curabitur posuere metus nec tellus venenatis placerat. Ut egestas neque in odio vulputate gravida. In at justo ac est laoreet eleifend vel quis arcu. Aliquam erat volutpat. Proin vitae vehicula neque. Nam tempus erat ac ante tincidunt tincidunt. Pellentesque eu nibh sapien. Nunc augue magna, lacinia eget congue eget, mattis id tortor. Fusce id vestibulum neque. Proin tincidunt tellus ut risus lobortis cursus. Duis sit amet urna vel sapien ullamcorper varius.</p>
                    </div>
                    <div>
                      <h2>Javascript</h2>
                      <div className="select-box">
                        <div className="select-box__current" tabIndex={2} onChange={() => { }}>
                          <div className="select-box__value">
                            <input className="select-box__input" type="radio" id={0} defaultValue={1} name="Responsive" defaultChecked="checked" />
                            <p className="select-box__input-text">Creamy Cream</p>
                          </div>
                          <div className="select-box__value">
                            <input className="select-box__input" type="radio" id={1} defaultValue={2} name="Responsive" />
                            <p className="select-box__input-text">Cheesy Cheese</p>
                          </div>
                          <div className="select-box__value">
                            <input className="select-box__input" type="radio" id={2} defaultValue={3} name="Responsive" />
                            <p className="select-box__input-text">Milky Milk too Milky as Milk</p>
                          </div>
                          <div className="select-box__value">
                            <input className="select-box__input" type="radio" id={3} defaultValue={4} name="Responsive" />
                            <p className="select-box__input-text">Honey Hon Hon</p>
                          </div>
                          <div className="select-box__value">
                            <input className="select-box__input" type="radio" id={4} defaultValue={5} name="Responsive" />
                            <p className="select-box__input-text">Toasted Toast</p>
                          </div>
                          <i className="bi bi-chevron-down select-box__icon"></i>
                        </div>
                        <ul className="select-box__list">
                          <li><label className="select-box__option" htmlFor={0}>Creamy Cream</label></li>
                          <li><label className="select-box__option" htmlFor={1}>Cheesy Cheese</label></li>
                          <li><label className="select-box__option" htmlFor={2}>Milky Milk too Milky as Milk</label></li>
                          <li><label className="select-box__option" htmlFor={3}>Honey Hon Hon</label></li>
                          <li><label className="select-box__option" htmlFor={4}>Toasted Toast</label></li>
                        </ul>
                        <div> hola
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ padding: '10px 20px 0px 20px' }} className='ptagdnwldopt'>STILL IN PROCESS... Pls close it as it is not working anything on this modal yet</div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="button-glitch" onClick={this.closeModal}>Close</button>
              <button className="button-glitch primbtn" onClick={() => { this.downloadResponsiveCV() }}>Download PDF</button>
            </Modal.Footer>
          </Modal>
        </div>

      </>
    )
  }

  downloadResponsiveCV() {
    console.log(this.responsivewidth.current.value, this.responsiveheight.current.value, this.state.responsiveselectboxmargin, this.responsivemargin.current.value, this.responsivequality.current.value)
  }

  async downloadCV() {
    let hidediv1 = document.getElementById('counts');
    this.setState({ showLoading: true });
    hidediv1.style.display = 'none'

    let scaleimg = 1.5;
    var container = document.querySelector("#cvdiv");
    window.html2canvas(container, { scale: scaleimg, allowTaint: true }).then(canvas => {
      let doc = new window.jsPDF({ orientation: 'p', unit: 'px', format: 'letter', compress: true });
      doc.width = doc.internal.pageSize.width;
      doc.height = doc.internal.pageSize.height;
      doc.margin = { horiz: 10, vert: 10 };
      doc.work = {
        width: doc.width - (doc.margin.horiz * 2),
        height: doc.height - (doc.margin.vert * 2)
      }

      let data = {
        width: container.offsetWidth * scaleimg,
        height: container.offsetHeight * scaleimg,
        ctx: canvas.getContext('2d', { willReadFrequently: true }),
        page: {}
      };

      data.page.width = data.width;
      data.page.height = (data.width * doc.work.height) / doc.work.width;

      const getData = function (imgData, width, height) {
        let oCanvas = document.createElement('canvas');
        oCanvas.width = width;
        oCanvas.height = height;
        let oCtx = oCanvas.getContext('2d', { willReadFrequently: true });
        oCtx.putImageData(imgData, 0, 0);
        return oCanvas.toDataURL('image/png');
      };

      let oImgData = null;
      let dataURL = null;
      let pages = Math.ceil(data.height / data.page.height);

      for (let i = 0; i < pages; i++) {
        if (i !== 0) {
          doc.addPage();
        }
        oImgData = data.ctx.getImageData(0, data.page.height * i, data.page.width, data.page.height);
        dataURL = getData(oImgData, data.page.width, data.page.height);
        doc.addImage(dataURL, 'PNG', doc.margin.horiz, doc.margin.vert, doc.work.width, doc.work.height, '', 'FAST');
      }

      doc.save('LuisChuyCV.pdf');
      hidediv1.style.display = '';
      this.setState({ showLoading: false });
    });

  }

  switchModes() {
    let colormodestrge = localStorage.getItem('colormode');

    // eslint-disable-next-line
    if (colormodestrge == 'lightmode') {
      localStorage.setItem('colormode', 'darkmode');
      window.dispatchEvent(new Event('changecolorevent'))
    } else {
      localStorage.setItem('colormode', 'lightmode');
      window.dispatchEvent(new Event('changecolorevent'))
    }
  }


  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  toggleModal() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  openLoading() {
    this.setState({ showLoading: true });
    setTimeout(() => {
      this.setState({ showLoading: false });
    }, 5000);
  }

  componentDidMount() {
    this.changesonstorage();
    window.addEventListener("changecolorevent", this.changesonstorage, false);
  }
  componentWillUnmount() {
    window.removeEventListener("changecolorevent", this.changesonstorage, false);
  }

  changesonstorage = () => {
    let changetest = localStorage.getItem('colormode');
    let aboutctn = document.getElementById("cvdiv");

    // eslint-disable-next-line
    if (changetest == 'lightmode') {
      aboutctn.classList.add("lightmode-aboutme")
      aboutctn.classList.remove("darkmode-aboutme");
    } else {
      aboutctn.classList.add("darkmode-aboutme")
      aboutctn.classList.remove("lightmode-aboutme");
    }
  }

  selectboxmarginresponsive(e) {
    this.setState({ responsiveselectboxmargin: e.target.defaultValue });
  }

}

export default AboutmeEN